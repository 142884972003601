<script setup lang="ts">
import {useModal, VaDropdown} from "vuestic-ui";
import swal from "sweetalert2";
import {ref, watch} from "vue";
import api from "@/services/proxy-api";
import DataTable from "@/components/DataTable.vue";
import {Credential} from "@/models/Credential";
import {formatDateTime} from "../utils/datetime";
import {
	UpdateCredentialRequest,
	CreateCredentialRequest,
} from "@/services/proxy-api/models/requests";
import {PaginatedResults} from "@/models/PaginatedResults";
import {Provider} from "@/models/Provider";

const {confirm} = useModal();

const isLoading = ref(false);
const showCreateModal = ref(false);
const showEditModal = ref(false);
const selectedItems = ref<Credential[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);
const columns = [
	{key: "CredentialId", label: "ID", sortable: true},
	{key: "Username", sortable: true},
	{key: "Password", sortable: true},
	{key: "ProviderId", sortable: true},
	{key: "CreatedDateUtc", label: 'Created Date', sortable: true},
	{key: "actions", width: 80},
];

const credentials = ref<PaginatedResults<Credential>>({records: [], pagination: {page: 0, page_size: 0, total_records: 0, total_pages: 0}});
const providers = ref<PaginatedResults<Provider>>({records: [], pagination: {page: 0, page_size: 0, total_records: 0, total_pages: 0}});

const newCredential = ref<CreateCredentialRequest>({
	Username: '',
	Password: ''
});
const editedCredential = ref<UpdateCredentialRequest>({
	CredentialId: 0,
	Username: '',
	Password: ''
});

const hidePasswords = ref(true);

const fetchData = async () => {
	isLoading.value = true;
	try {
		const response = await api.getCredentials({pageSize: pageSize.value, pageIndex: currentPage.value});
		providers.value = await api.getProviders(undefined, undefined, {pageSize: 200, pageIndex: 1});
		credentials.value = response;
	} catch (e: any) {
		await swal.fire('Error', e.message, 'error');
	} finally {
		isLoading.value = false;
	}
};

const createCredential = async (request: CreateCredentialRequest) => {
	if (!request.Username || !request.Password || !request.ProviderId) {
		await swal.fire('Error', 'All fields are required', 'error');
		return;
	}

	try {
		await api.createCredential(request);
	} catch (error: any) {
		if (error?.response?.status === 400) {
			let errorMessage = '';
			for (const field in error.response.data.Errors) {
				errorMessage += `${field}: ${error.response.data.Errors[field].join(', ')}\n`;
			}
			await swal.fire('Error', errorMessage, 'error');
		} else {
			await swal.fire('Error', 'An error occurred while creating the credential', 'error');
		}
	} finally {
		await fetchData()
	}
};

const askDeleteConfirmation = async (credential: Credential) => {
	const ok = await confirm(`Are you sure you want to delete these credentials (ID: ${credential.CredentialId})?`)
	if (ok) {
		try {
			await api.deleteCredential(credential.CredentialId);
		} catch (e) {
			await swal.fire('Error', 'Failed to delete credentials.', 'error');
		} finally {
			await fetchData()
		}
	}
};

const askDeleteBulkConfirmation = async () => {
	const ok = await confirm(`Are you sure you want to delete ${selectedItems.value.length} selected credentials'}?`)
	if (ok) {
		const failedToDelete: number[] = [];
		const promises = selectedItems.value.map(async (item: Credential) => {
			try {
				await api.deleteCredential(item.CredentialId);
			} catch (e) {
				failedToDelete.push(item.CredentialId);
			}
		});
		await Promise.all(promises);
		if (failedToDelete.length) {
			await swal.fire('Error', `Failed to delete the credentials with IDs: ${failedToDelete.join(', ')}`, 'error');
		} else {
			await swal.fire('Success', 'All selected credentials deleted successfully.', 'success');
		}
		await fetchData()
		selectedItems.value = [];
	}
};

const updateCredential = async (request: UpdateCredentialRequest) => {
	try {
		await api.updateCredential(request);
	} catch (error: any) {
		if (error?.response?.status === 400) {
			let errorMessage = '';
			for (const field in error.response.data.Errors) {
				errorMessage += `${field}: ${error.response.data.Errors[field].join(', ')}\n`;
			}
			await swal.fire('Error', errorMessage, 'error');
		} else {
			await swal.fire('Error', 'An error occurred while updating the credentials', 'error');
		}
	} finally {
		await fetchData()
	}
};

fetchData()

watch(currentPage, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});

watch(pageSize, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});
</script>

<template>
	<p class="font-bold text-2xl pb-3 text-center">Credentials</p>
	<DataTable
		v-model:selected-items="selectedItems"
		v-model:current-page="currentPage"
		v-model:page-size="pageSize"
		:items="credentials"
		:columns="columns"
		:loading="isLoading"
		:on-create="async () => {
			showCreateModal = true;
		}"
		:on-edit="async (credential: Credential) => {
			editedCredential = {
				CredentialId: credential.CredentialId,
				Username: credential.Username ?? '',
				Password: credential.Password ?? '',
				ProviderId: credential.ProviderId ?? 0,
			};
			showEditModal = true;
		}"
		:on-delete="askDeleteConfirmation"
		:on-bulk-delete="askDeleteBulkConfirmation">
		<template #top-left>
			<VaSwitch
				v-model="hidePasswords"
				class="mr-3 mt-1.5"
				label="Hide passwords"
				size="small"/>
		</template>
		<template #cell(Password)="{ value }">
			{{ hidePasswords ? '**********' : value }}
		</template>
		<template #cell(CreatedDateUtc)="{ value }">
			{{ value && formatDateTime(value) }}
		</template>
	</DataTable>

	<VaModal
		v-model="showCreateModal"
		ok-text="Create"
		cancel-text="Cancel"
		close-button
		@ok="() => {
			createCredential(newCredential);
		}"
	>
		<h2 class="mb-6 text-3xl font-extrabold text-gray-900">
			New Credential
		</h2>
		<div class="grid md:grid-cols-2 gap-x-5">
			<VaInput
				v-model="newCredential.Username"
				label="Username*"
				placeholder="Enter the username"
				:isRequired="true"
				class="mb-5"></VaInput>
			<VaSelect
				v-model="newCredential.ProviderId"
				label="Provider*"
				placeholder="Select the provider"
				:isRequired="true"
				:options="providers.records.map(p => ({ text: p.Name, value: p.ProviderId }))"
				value-by="value"
				class="mb-5"></VaSelect>
			<VaInput
				v-model="newCredential.Password"
				label="Password*"
				placeholder="Enter the password"
				type="password"
				:isRequired="true"
				class="mb-5"></VaInput>
		</div>
	</VaModal>
	<VaModal
		v-model="showEditModal"
		ok-text="Save"
		cancel-text="Cancel"
		close-button
		@ok="() => {
			updateCredential(editedCredential);
		}"
	>
		<h2 class="mb-6 text-3xl font-extrabold text-gray-900">
			Edit Credential
		</h2>
		<div class="grid md:grid-cols-2 gap-x-5">
			<VaInput
				v-model="editedCredential.Username"
				label="Username"
				placeholder="Enter the username"
				class="mb-5"></VaInput>
			<VaSelect
				v-model="editedCredential.ProviderId"
				label="Provider"
				placeholder="Select the provider"
				:options="providers.records.map(p => ({ text: p.Name, value: p.ProviderId }))"
				value-by="value"
				class="mb-5"></VaSelect>
			<VaInput
				v-model="editedCredential.Password"
				label="Password"
				placeholder="Enter the password"
				class="mb-5"></VaInput>
		</div>
	</VaModal>
</template>
